import { PortableText as PortableTextBase, PortableTextComponents } from '@portabletext/react';
import { ArbitraryTypedObject, PortableTextBlock } from '@portabletext/types/src';
import { Children, isValidElement, ReactNode } from 'react';

import {
  Box,
  getIFrameYoutubeComponent,
  Image,
  Link,
  LinkTranslatedStyledAsLink,
  Tweet,
  Typography,
} from '@core/component';
import { useContextMediaQuery } from '@core/context';
import { getSanityPageRoute, urlFor } from '@core/sanity';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  ETypographyVariant,
  ThemeColorType,
} from '@core/type';
import {
  ESanityField,
  ESanityWidget,
  SanityLinkObjProps,
  SanityVideo,
  SanityWidget,
} from '@core/type/sanity';

import { Blockquote } from './Blockquote';
import { Heading } from './Heading';
import { OrderedList, UnorderedList } from './List';
import { TextItalic } from './TextItalic';
import { PortableTextChildProps, PortableTextComponentEntity } from './interface-portable-text';

const formatPortableText = (children: ReactNode): ReactNode => {
  return Children.map(children, (childNode) => {
    const child = childNode as PortableTextChildProps;

    if (typeof child === 'string') {
      return child;
    } else if (isValidElement(child) && child.props.children) {
      if (typeof child.props.children === 'string') {
        return {
          ...child,
          props: {
            ...child.props,
            children: child.props.children,
          },
        };
      }
      // Recursively format the nested children
      return {
        ...child,
        props: {
          ...child.props,
          children: formatPortableText(child.props.children),
        },
      };
    }
    return childNode;
  });
};

export const getComponents = (
  isPhone: boolean,
  isMobile: boolean,
  themeColorList: ThemeColorType,
): PortableTextComponents => ({
  marks: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    textColor: ({ children, value }) => <span style={{ color: value.value }}>{children}</span>,
    lineThrough: ({ children }) => (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      <mark
        style={{
          textDecoration: `line-through ${
            themeColorList[EColor.MAIN][EColor.TEXT][EColor.SECONDARY][EColor.DARK]
          }`,
          background: 'white',
        }}
      >
        {children}
      </mark>
    ),
    highlight: ({ children }) => (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      <mark
        style={{
          background: themeColorList[EColor.MAIN][EColor.TEXT][EColor.SECONDARY][EColor.DARK],
        }}
      >
        {children}
      </mark>
    ),
    left: ({ children }) => (
      <Box as={EActionAttribute.SPAN} display={'block'} textAlign={'left'}>
        {formatPortableText(children)}
      </Box>
    ),
    center: ({ children }) => (
      <Box as={EActionAttribute.SPAN} display={'block'} textAlign={'center'}>
        {formatPortableText(children)}
      </Box>
    ),
    right: ({ children }) => (
      <Box as={EActionAttribute.SPAN} display={'block'} textAlign={'right'}>
        {formatPortableText(children)}
      </Box>
    ),
    em: ({ children }) => <TextItalic as={EActionAttribute.SPAN}>{children}</TextItalic>,
    strong: ({ children }) => (
      <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD}>
        {formatPortableText(children)}
      </Typography>
    ),
    [ESanityField.INTERNAL_LINK]: ({ children, value }) => {
      const { keyRoute, language, slug, metalIso } = value as SanityLinkObjProps;
      const href = getSanityPageRoute(keyRoute, language, slug, metalIso);

      return (
        <LinkTranslatedStyledAsLink color={EColor.ACCENT} href={href} locale={false}>
          {formatPortableText(children)}
        </LinkTranslatedStyledAsLink>
      );
    },
    [ESanityField.LINK]: ({ children, value }) => {
      const props = value as { href: string; isTargetBlank: boolean };

      return (
        <Link
          color={EColor.ACCENT}
          target={props?.isTargetBlank ? '_blank' : undefined}
          href={props?.href || ''}
        >
          {formatPortableText(children)}
        </Link>
      );
    },
  },
  list: {
    bullet: ({ children }) => (
      <UnorderedList isMobile={isMobile}>{formatPortableText(children)}</UnorderedList>
    ),
    number: ({ children }) => (
      <OrderedList isMobile={isMobile}>{formatPortableText(children)}</OrderedList>
    ),
  },
  block: {
    h1: ({ children }) => (
      <Heading variant={ETypographyVariant.H1} as={EActionAttribute.H1}>
        {formatPortableText(children)}
      </Heading>
    ),
    h2: ({ children }) => (
      <Heading variant={ETypographyVariant.H2} as={EActionAttribute.H2}>
        {formatPortableText(children)}
      </Heading>
    ),
    h3: ({ children }) => (
      <Heading variant={ETypographyVariant.H3} as={EActionAttribute.H3}>
        {formatPortableText(children)}
      </Heading>
    ),
    h4: ({ children }) => (
      <Heading variant={ETypographyVariant.H4} as={EActionAttribute.H4}>
        {formatPortableText(children)}
      </Heading>
    ),
    h5: ({ children }) => (
      <Heading variant={ETypographyVariant.H5} as={EActionAttribute.H5}>
        {formatPortableText(children)}
      </Heading>
    ),
    h6: ({ children }) => (
      <Heading variant={ETypographyVariant.H6} as={EActionAttribute.H6}>
        {formatPortableText(children)}
      </Heading>
    ),
    normal: ({ children }) => (
      <Typography marginTop={isPhone ? '11px' : '16px'}>{formatPortableText(children)}</Typography>
    ),
    blockquote: ({ children }) => <Blockquote>{formatPortableText(children)}</Blockquote>,
  },
  types: {
    [ESanityField.IMAGE]: ({ value }) => {
      return (
        <Box as={EActionAttribute.SPAN} width={'100%'} display="block" position="relative">
          <Image
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            src={urlFor(value.asset).url()}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            alt={value.alt || 'image'}
            style={{
              objectFit: 'contain',
              height: null,
              position: null,
            }}
          />
        </Box>
      );
    },
    [ESanityField.EXTERNAL_IMAGE]: ({ value }) => {
      return (
        <Box as={EActionAttribute.SPAN} width={'100%'} display="block" position="relative">
          <Image
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            src={value.url}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            alt={value.alt || 'external image'}
            style={{
              objectFit: 'contain',
              height: null,
              position: null,
            }}
          />
        </Box>
      );
    },
    videoComponent: ({ value }) => {
      return getIFrameYoutubeComponent(value as SanityVideo);
    },
    [ESanityWidget.TWEET]: ({ value }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      return <Tweet id={value.id} />;
    },
  },
});

export const PortableText = ({
  value,
  themeColorList,
}: {
  value: PortableTextBlock | ArbitraryTypedObject;
  themeColorList: ThemeColorType;
}) => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <PortableTextBase value={value} components={getComponents(isPhone, isMobile, themeColorList)} />
  );
};

export const getPortableTextComponent = (section: SanityWidget, themeColorList: ThemeColorType) => (
  <PortableText
    value={(section as PortableTextComponentEntity).portableText}
    themeColorList={themeColorList}
  />
);
